import React from 'react'
import styled from 'styled-components'
import { StaticQuery, Link, graphql } from 'gatsby'
import { Box, Card, Button, Flex, Heading, Image } from 'rebass'
import { withTheme } from 'styled-components'

import Container from './container'

const ImageQuery = graphql`
  {
    placeholderImage: file(name: { eq: "etichette" }) {
      publicURL
    }
  }
`

const ImageContainer = styled(Box)`
  text-align: right;
  @media (min-width: 1100px) {
    text-align: center;
  }
`

const CourseLink = styled(Link)`
  float: right;
  color: #ff4a28;
  transition: color 0.3s;

  &:visited {
    color: #ff4a28;
  }

  @media (max-width: 700px) {
    float: none;
    display: block;
    text-align: center;
    margin: 16px auto 0 auto;
  }
`

const ActiveCourse = styled(Card)`
  position: relative;
  line-height: 140%;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 6px);
    left: 16px;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background: #21bb68;

    @media (max-width: 700px) {
      display: none;
    }
  }

  @media (max-width: 700px) {
    text-align: center;
  }

  @media (max-width: 480px) {
    display: none;
  }
`

const Hero = ({ theme, children, activeCourse }) => (
  <Box bg={theme.colors.lightblue} py={[3, 3, 0]}>
    <Container>
      <Flex alignItems="center">
        <Box width={[1, 1, 2 / 3]} pt={4}>
          <Heading
            fontFamily={theme.fonts.serif}
            fontWeight="normal"
            fontSize={[5, 5, 6]}
            mb={4}
          >
            {children}
          </Heading>
          <Link to="/servizi">
            <Button variant="primary" fontWeight="normal !important">
              Scopri i nostri servizi
            </Button>
          </Link>
        </Box>
        <ImageContainer
          width={[0, 0, 1 / 3]}
          mx={-56}
          css={{ textAlign: 'right' }}
        >
          <StaticQuery
            query={ImageQuery}
            render={data => (
              <Image
                src={data.placeholderImage.publicURL}
                width="75%"
                alt="Pannelli Merci Pericolose"
              />
            )}
          />
        </ImageContainer>
      </Flex>
      {activeCourse && activeCourse.node && (
        <ActiveCourse
          mt={5}
          mb={4}
          bg="#fff"
          py={3}
          pl={[3, '40px', '40px']}
          pr={3}
          borderRadius={100}
          boxShadow="1px 1px 4px 1px hsla(0,0%,0%,0.1)"
        >
          Iscrizioni aperte per il corso "{activeCourse.node.frontmatter.title}"
          <CourseLink to={activeCourse.node.fields.slug}>
            Maggiori informazioni →
          </CourseLink>
        </ActiveCourse>
      )}
    </Container>
  </Box>
)

export default withTheme(Hero)
