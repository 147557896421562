import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Card, Flex, Box, Heading, Text } from 'rebass'
import { Link as GatsbyLink } from 'gatsby'
import moment from 'moment'

import Layout from '../components/layout'
import Hero from '../components/hero'
import SEO from '../components/seo'
import Container from '../components/container'

const Link = styled(GatsbyLink)`
  color: #ff4a28;
  transition: color 0.3s;

  &:visited {
    color: #ff4a28;
  }

  span {
    transition: all 0.3s;
  }

  &:hover {
    span {
      margin-left: 4px;
    }
  }
`

const IndexPage = ({ data }) => {
  moment.locale('it')

  const home = data.allMarkdownRemark.edges.find(page =>
    page.node.fields.slug.includes('/home/')
  )

  const services = data.allMarkdownRemark.edges
    .filter(page => page.node.fields.slug.includes('/servizi/'))
    .sort((el1, el2) => el1.node.frontmatter.order - el2.node.frontmatter.order)

  const courses = data.allMarkdownRemark.edges
    .filter(page => page.node.fields.slug.includes('/formazione/'))
    .sort((el1, el2) => el1.node.frontmatter.order - el2.node.frontmatter.order)

  const [activeCourse, setActiveCourse] = useState({})
  const [runningCourses, setRunningCourses] = useState([])
  const [today, setToday] = useState({})

  useEffect(() => {
    const today = moment()
    const activeCourse = courses.find(course => {
      if (course.node.frontmatter.next_course) {
        const courseStartDate = moment(
          course.node.frontmatter.next_course.start_date,
          'DD-MM-YYYY'
        )
        return today.isBefore(courseStartDate)
      }

      return false
    })

    const runningCourses = courses
      .filter(course => {
        if (course.node.frontmatter.next_course) {
          const courseEndDate = moment(
            course.node.frontmatter.next_course.end_date,
            'DD-MM-YYYY'
          )
          return today.isBefore(courseEndDate)
        }

        return false
      })
      .sort((el1, el2) =>
        moment(el1.node.frontmatter.next_course.start_date, 'DD-MM-YYYY').diff(
          moment(el2.node.frontmatter.next_course.start_date, 'DD-MM-YYYY')
        )
      )

    setToday(today)
    setActiveCourse(activeCourse)
    setRunningCourses(runningCourses)
  }, [])

  const Service = ({ title, description, slug }) => (
    <Box width={[1, 1 / 2, 1 / 2]} mb={[0, 3, 0]} p={4}>
      <Heading fontWeight="500" fontSize={3} mb={3}>
        {title}
      </Heading>
      <Text lineHeight="140%" mb={3}>
        {description}
      </Text>
      <Link to={slug}>
        Maggiori informazioni <span>→</span>
      </Link>
    </Box>
  )

  const CourseBox = ({ course }) => {
    const courseStartDate = moment(
      course.node.frontmatter.next_course.start_date,
      'DD-MM-YYYY'
    )
    const alreadyStarted = today.isAfter(courseStartDate)

    return (
      <Link to={course.node.fields.slug}>
        <Card
          borderRadius={4}
          boxShadow="1px 1px 4px 1px hsla(0,0%,0%,0.1)"
          p={3}
          mb={4}
          css={{ cursor: 'pointer', position: 'relative' }}
        >
          <Text
            color={alreadyStarted ? '#f06449' : '#21bb68'}
            fontWeight="500"
            fontSize="15px"
            mb={2}
          >
            {alreadyStarted
              ? `Il corso è iniziato il ${course.node.frontmatter.next_course.start_date} e finirà il ${course.node.frontmatter.next_course.end_date}`
              : `Il corso inizierà il ${course.node.frontmatter.next_course.start_date}`}
          </Text>
          <Text fontSize="18px" fontWeight="500" color="#000">
            {course.node.frontmatter.title}
          </Text>
        </Card>
      </Link>
    )
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Hero activeCourse={activeCourse}>
        {home.node.frontmatter.hero_message}
      </Hero>
      <Container my={4} css={{ overflowX: 'hidden' }}>
        <Heading
          fontSize={5}
          fontWeight="500"
          fontFamily="Playfair Display, serif"
          mb={3}
        >
          I nostri servizi
        </Heading>
        <Flex pb={4} flexWrap="wrap" mx={-4}>
          {services.map(s => {
            const { title, description } = s.node.frontmatter
            const { slug } = s.node.fields
            return (
              <Service
                title={title}
                description={description}
                slug={slug}
                key={slug}
              />
            )
          })}
        </Flex>
        {runningCourses.length >= 1 && (
          <Box>
            <Heading
              fontSize={5}
              fontWeight="500"
              fontFamily="Playfair Display, serif"
              mb={4}
            >
              Corsi in programmazione
            </Heading>
            {runningCourses.map(course => (
              <CourseBox course={course} key={course.node.frontmatter.title} />
            ))}
          </Box>
        )}
      </Container>
    </Layout>
  )
}

export const PagesQuery = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            hero_message
            order
            next_course {
              end_date
              start_date
            }
          }
        }
      }
    }
  }
`

export default IndexPage
